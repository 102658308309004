<template>
    <div id="page-search-details">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="{ name: 'bulk-previous' }">Back to Previous Searches</router-link>

                    <h1 class="heading">Search details</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <template v-if="loaded">
                        <template v-if="have_locations">
                            <bulk-search-addresses
                                v-model="selected"

                                :prequals="prequals"

                                :processing="processing"

                                @remove="onAddressServicesRemove"
                                @place-order="onAddressServicesPlaceOrder"
                                @export-csv="onAddressServicesExportCSV"
                            ></bulk-search-addresses>

                            <app-pagination
                                v-if="pagination.total"

                                v-model="pagination.page"

                                :total="pagination.total"
                                :limit="pagination.limit"
                                :page-range="pagination.range"

                                :disabled="processing"

                                @change="onPageChange"
                            />
                        </template>

                        <app-placeholder v-else message="There are no search results" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appPagination from '@/components/app-pagination'
import appPlaceholder from '@/components/app-placeholder'

import bulkSearchAddresses from './components/bulk-search-addresses'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appPagination,
        appPlaceholder,

        bulkSearchAddresses,
    },

    data() {
        return {
            locations_flat: {},

            selected: {},
            prequals: [],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,
            processing: false,

            loaded: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getBulkSearchAddresses()
        },

        getBulkSearchAddresses(page) {
            this.loading = true

            let params = {
                'BulkPreQualUUID': this.uuid,
                'IncludeInactive': true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
            }

            return this.$store.dispatch('api_bulkprequal/GetPreQualsPaginated', params)
                .then(response => {

                    // --------------------------------------------------

                    for (let i = 0, len = response.PreQuals.length; i < len; i++) {
                        // console.log(response.PreQuals[i].Locations.length)
                        for (let j = 0, lenj = response.PreQuals[i].Locations.length; j < lenj; j++) {
                            // console.log(response.PreQuals[i].Locations[j])

                            const key = `${ response.PreQuals[i].Locations[j].LocationProvider }|${ response.PreQuals[i].Locations[j].LocationProviderIDX }`

                            if (!(key in this.locations_flat)) {
                                this.locations_flat[key] = {
                                    ...response.PreQuals[i].Locations[j],
                                    address: response.PreQuals[i].Locations[j].Formatted,
                                }
                            }
                        }
                    }

                    // console.log('this.locations_flat', this.locations_flat)

                    // --------------------------------------------------

                    this.prequals = response.PreQuals

                    const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo

                    this.pagination.page = PageNumber
                    this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                    }

                    this.loading = false

                    this.loaded = true

                    return Promise.resolve(response)
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.prequals = []

                    this.loading = false

                    this.loaded = false

                    return Promise.reject(error)
                })
        },

        /**
         * Filtering
         */
        onPageChange(page) {
            this.getBulkSearchAddresses(page)
        },

        /**
         * Actions with address services
         */
        onAddressServicesRemove() {
            this.loading = true

            const uuids = Object.keys(this.selected)

            const promises = []

            for (let i = 0, len = uuids.length; i < len; i++) {
                const params = {
                    UUID: uuids[i],
                }

                promises.push(this.$store.dispatch('api_bulkprequal/DeletePreQualByUUID', params))
            }

            Promise.all(promises)
                .then(() => {
                    this.getBulkSearchAddresses()
                        .then(() => {
                            this.selected = {}

                            this.loading = false
                        })
                        .catch(error => {
                            this.$store.dispatch('addToast', {
                                message: errMessage(error),
                                type: 'error',
                                delay: 5000,
                            })

                            this.loading = false
                        })
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.loading = false
                })
        },

        onAddressServicesPlaceOrder() {
            const locations = {}

            for (const key in this.selected) {
                for (let i = 0, len = this.selected[key].length; i < len; i++) {
                    locations[this.selected[key][i]] = this.locations_flat[this.selected[key][i]]
                }
            }
// console.log('save locations', locations)
            this.$store.commit('locations/saveLocations', locations)

            this.$router.push({ name: 'fibre-bulk-add-service' })
        },

        onAddressServicesExportCSV() {
            this.loading = true

            const PreQualUUIDSearchs = Object.keys(this.selected).map(uuid => ({
                UUID: uuid,
            }))

            const params = {
                SPID: this.current_spid,

                PreQualUUIDSearchs,
            }

            this.$store.dispatch('api_bulkprequal/GetPreQualReport', params)
                .then(({ CSVData }) => {
                    const blob = new Blob([atob(CSVData)], { type: 'text/csv;charset=utf-8;' })

                    const link = document.createElement('a')

                    const url = URL.createObjectURL(blob)

                    link.setAttribute('href', url)
                    link.setAttribute('download', 'Report.csv')
                    link.style.visibility = 'hidden'

                    document.body.appendChild(link)

                    link.click()

                    document.body.removeChild(link)

                    this.selected = {}

                    this.loading = false
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.loading = false
                })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        uuid() {
            return this.$route.params.uuid
        },

        have_locations() {
            let have_locations = false

            for (let i = 0, len = this.prequals.length; i < len; i++) {
                if (this.prequals[i].Locations.length) {
                    have_locations = true
                    break
                }
            }

            return have_locations
        },
    },
}
</script>

<style lang="scss">
#page-search-details {
    margin: 24px 0 80px;

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .btn-back-to {
        margin-bottom: 12px;
    }

    .bulk-search-addresses {
        margin-top: 30px;
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $mobile-size) {
    #page-search-details {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .bulk-search-addresses {
            margin-top: 15px;
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>