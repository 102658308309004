<template>
    <div class="bulk-search-prequals">
        <div class="head">
            <div class="info">
                <div v-if="selected_count">Selected: <span>{{ selected_count }}</span></div>
            </div>

            <div class="actions">
                <!-- <button class="btn btn-with-icon-primary action-remove"      @click="remove"     :disabled="!is_at_least_one_prequal_selected"><span>Remove</span></button> -->

                <button class="btn btn-with-icon-primary action-place-order" @click="placeOrder" :disabled="!is_at_least_one_prequal_selected"><span>Place Order</span></button>

                <button class="btn btn-with-icon-primary action-export"      @click="exportCSV"  :disabled="!is_at_least_one_prequal_selected"><span>Export CSV</span></button>
            </div>
        </div>

        <div class="prequals">
            <template v-for="thisPreQual in prequals_list">
                <div class="services" v-if="thisPreQual.addresses_count > 0" :key="thisPreQual.UUID">
                    <div class="service" :class="{ opened: thisPreQual.UUID in value }">
                        <app-checkbox :value="isServiceChecked(thisPreQual.UUID)" @change="toggleSelect(thisPreQual.UUID)" :disabled="processing" @click.stop>{{ thisPreQual.SearchText }}</app-checkbox>
                    </div>

                    <div class="collapse" :class="{ opened: opened[thisPreQual.UUID] }" v-if="thisPreQual.addresses_count > 1">
                        <div class="toggle" @click="toggleOpen(thisPreQual.UUID)">
                            <span>{{ thisPreQual.addresses_count }} addresses</span>
                            <i></i>
                        </div>
                        <div class="content">
                            <div class="services-list">
                                <div class="service" v-for="(address, addresskey) in thisPreQual.mut_addresses" :key="addresskey" @click.stop>
                                    <app-checkbox :value="isLocationChecked(thisPreQual.UUID, address)" @change="toggleSelect(thisPreQual.UUID, address)" :disabled="processing">{{ address.AddressText }}</app-checkbox>

                                    <div class="service-types">
                                        <div v-for="(service, servicekey) in processAddressServices(address.Services)"
                                            :key="servicekey"
                                            class="tag"
                                            :class="[service.ServiceType, {'not-available': !service.count}]"
                                        >{{ service.ServiceType }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="collapse opened" v-else-if="thisPreQual.addresses_count == 1">
                        <div class="content">
                            <div class="services-list">
                                <div class="service" v-for="(address, addresskey) in thisPreQual.mut_addresses" :key="addresskey" @click.stop>
                                    <app-checkbox :value="isLocationChecked(thisPreQual.UUID, address)" @change="toggleSelect(thisPreQual.UUID, address)" :disabled="processing">{{ address.AddressText }}</app-checkbox>

                                    <div class="service-types">
                                        <div v-for="(service, servicekey) in processAddressServices(address.Services)"
                                            :key="servicekey"
                                            class="tag"
                                            :class="[service.ServiceType, {'not-available': !service.count}]"
                                        >{{ service.ServiceType }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service" v-else :key="thisPreQual.UUID">
                    <app-checkbox
                        :value="isServiceChecked(thisPreQual.UUID)"
                        :disabled="true"

                        @change="toggleSelect(thisPreQual.UUID)"
                    >{{ thisPreQual.SearchText }} - No Match</app-checkbox>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appCheckbox from '@/components/app-checkbox'

export default {
    props: {
        value: { type: Object, required: true },
        prequals: { type: Array, required: true },

        processing: { type: Boolean, default: false },
    },

    components: {
        appCheckbox,
    },

    data() {
        return {
            opened: {},
        }
    },

    created() {
        this.openMultiServices()
    },

    methods: {
        remove() {
            this.$emit('remove')
        },
        placeOrder() {
            this.$emit('place-order')
        },
        exportCSV() {
            this.$emit('export-csv')
        },

        toggleSelect(uuid, location) {
            if (!this.processing) {
                if (location) {
                    if (uuid in this.value) {
                        const locations = this.value[ uuid ]
                        const index = locations.indexOf(location.Key)

                        if (index > -1) {
                            locations.splice(index, 1)
                        } else {
                            locations.push(location.Key)
                        }

                        if (locations.length) {
                            this.$set(this.value, uuid, locations)
                        } else {
                            this.$delete(this.value, uuid)
                        }
                    } else {
                        this.$set(this.value, uuid, [ location.Key ])
                    }
                } else {
                    if (uuid in this.value) {
                        this.$delete(this.value, uuid)
                    } else {
                        this.$set(this.value, uuid, Object.keys(this.prequals_list[uuid].mut_addresses))
                    }
                }

                this.$emit('input', this.value)
                this.$emit('change', this.value)
            }
        },

        isServiceChecked(uuid, service) {
            let is_checked = false

            if (uuid in this.value) {
                if (service) {
                    if (this.value[uuid].indexOf(service) > -1) {
                        is_checked = true
                    }
                } else {
                    is_checked = true
                }
            }

            return is_checked
        },

        isLocationChecked(uuid, location) {
            let is_checked = false

            if (uuid in this.value) {
                if (this.value[uuid].indexOf(location.Key) > -1) {
                    is_checked = true
                }
            }

            return is_checked
        },

        toggleOpen(uuid) {
            if (!this.processing) {
                if (uuid in this.opened) {
                    this.$delete(this.opened, uuid)
                } else {
                    this.$set(this.opened, uuid, true)
                }
            }
        },

        openMultiServices() {
            for (const uuid in this.prequals_list) {
                if (this.prequals_list[uuid].is_multy_services) {
                    this.$set(this.opened, uuid, true)
                }
            }
        },

        processAddressServices(address_services) {
            return this.search_services.map(service_type => {
                return {
                    ServiceType: service_type,
                    count: address_services.filter(({ ServiceType }) => ServiceType == service_type ).length
                }
            })
        },
    },

    watch: {
        prequals() {
            this.openMultiServices()
        },
    },

    computed: {
        ...mapGetters([
            'bulk_services',
        ]),

        is_at_least_one_prequal_selected() {
            return Object.keys(this.value).length > 0
        },

        selected_count() {
            let count = 0

            const services = Object.values(this.value)
            for (let i = 0, len = services.length; i < len; i++) {
                count+= services[i].length
            }

            return count
        },

        prequals_list() {
            let prequals = {}
            // console.log('this.prequals', this.prequals)

            for (let i = 0, len = this.prequals.length; i < len; i++) {
                let thisPreQual = this.prequals[i]
                thisPreQual.mut_addresses = {}

                const servicesByLocation = {}
                for (let si = 0, srvlen = thisPreQual.Services.length; si < srvlen; si++) {
                    var key = `${thisPreQual.Services[si].LocationProvider}|${thisPreQual.Services[si].LocationProviderIDX}`

                    let currentServices = servicesByLocation[key]
                    if (!Array.isArray(currentServices)) {
                        currentServices = []
                    }
                    let thisServiceDetail = {
                         ServiceType: thisPreQual.Services[si].ServiceType,
                    }
                    currentServices.push(thisPreQual.Services[si])
                    if (!servicesByLocation[key]) {
                        servicesByLocation[key] = []
                    }
                    servicesByLocation[key] = currentServices
                }
                // console.log('servicesByLocation[key]', servicesByLocation[key])

                for (let li = 0, loclen = thisPreQual.Locations.length; li < loclen; li++) {
                    var key = `${thisPreQual.Locations[li].LocationProvider}|${thisPreQual.Locations[li].LocationProviderIDX}`

                    const pattern = /^[^,]/

                    if (pattern.test(thisPreQual.Locations[li].Formatted)) {
                        thisPreQual.mut_addresses[key] = {
                            Key: key,
                            ParentUUID: thisPreQual.UUID,
                            AddressText: thisPreQual.Locations[li].Formatted,
                            Services: [],
                        }

                        if (servicesByLocation[key]) {
                            thisPreQual.mut_addresses[key].Services = servicesByLocation[key]
                        }
                    }
                }

                thisPreQual.addresses_count = Object.keys(thisPreQual.mut_addresses).length

                prequals[ thisPreQual.UUID ] = thisPreQual

                /*
                    if (thisPreQual.addresses_count) {
                        prequals[ thisPreQual.UUID ] = thisPreQual
                    }
                */
            }

            return prequals
        },

        search_services() {
            /** @todo список сервисов должен приходить с сервера вместе с данными по поисковому запросу????  */
            return ['dsl', 'fibre']
        },

        services_titles() {
            let titles = {
                null: '???',
                undefined: '???',
            }

            for (const service in this.bulk_services) {
                titles[ service ] = this.bulk_services[ service ].TitleAbbreviation
                                    || this.bulk_services[ service ].Title
            }

            return titles
        },
    },
}
</script>

<style lang="scss">
.bulk-search-prequals {
    padding: 16px 0;
    background: var(--color-component-bg-primary);
    border-radius: $border-radius-secondary;
    box-shadow: var(--box-shadow-primary);

    & > .head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 8px 24px 10px;

        .info {
            flex-shrink: 0;
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 24px;
            color: var(--color-table-secondary);
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-grow: 1;
            margin-bottom: 10px;

            .btn {
                width: auto;
                height: 24px;
                margin-left: 30px;

                @include icon-before($icon-remove);

                // &.action-remove { @include icon-before-code($icon-remove); }
                &.action-place-order { @include icon-before-code($icon-place-order); }
                &.action-export { @include icon-before-code($icon-export); }
            }
        }
    }

    .prequals {
        color: var(--color-table-primary);

        .app-checkbox {
            &:before {
                margin-right: 22px;
            }
        }

        .service {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 0 24px;
            padding: 12px 0;
            border-top: 1px solid var(--color-divider);
            user-select: none;

            &:first-child {
                border: none;
            }

            .app-checkbox {
                span {
                    text-transform: uppercase;
                }
            }

            .service-types {
                flex: 1 0 450px;
                max-width: 450px;
                margin-left: auto;

                display: flex;
                justify-content: flex-end;

                .tag {
                    flex: 0 0 33%;
                    max-width: 125px;
    
                    display: flex;
                    // justify-content: space-between;
                    align-items: center;
                    color: var(--color-text-primary);
                    font-weight: bold;
                    padding: 4px 12px 4px 8px;
                    background: var(--color-table-tag-secondary);
                    border-radius: $border-radius-primary;
                    cursor: default;
                    user-select: none;
                    white-space: nowrap;

                    margin: 1px 8px;
    
                    &:before {
                        margin-right: 4px;
    
                        @include icon($icon-service-fibre, 18px);
                    }

                    // &.fibre { @include icon-before-code($icon-service-fibre); }
                    // &.fwa { @include icon-before-code($icon-service-fwa); }
                    // &.dsl { @include icon-before-code($icon-service-dsl); }

                    &.not-available {
                        opacity: 0.4;
                    }
                }
            }

        }

        & > .service {
            &:first-child {
                padding: 4px 0 12px;
            }
        }

        .services {
            margin: 0 24px;
            border-top: 1px solid var(--color-divider);

            .service {
                margin: 0;
                padding: 16px 0 8px;
                border: none;
            }

            &:first-child {
                border: none;

                & > .service {
                    padding: 4px 0 8px;
                }
            }

            .collapse {
                margin: 0 42px 16px 46px;

                .toggle {
                    display: flex;
                    width: fit-content;
                    padding: 4px 12px 4px 24px;
                    background-color: transparent;
                    border-radius: $border-radius-primary;
                    cursor: pointer;
                    transition: background-color $transition-duration-primary;

                    i {
                        position: relative;
                        display: block;
                        width: 24px;
                        height: 24px;
                        margin-left: 2px;

                        &:before {
                            position: absolute;
                            top: 1px;
                            left: 0;

                            @include icon($icon-chevron-bottom);
                        }
                    }

                    &:hover {
                        background-color: var(--color-collapse-bg);
                    }
                }

                .content {
                    display: none;
                    padding: 12px 24px;
                    background-color: var(--color-collapse-bg);
                    border-radius: 0 $border-radius-primary $border-radius-primary $border-radius-primary;

                    .services-list {
                        .service {
                            margin-bottom: 8px;
                            padding: 0;

                            .app-checkbox {
                                span {
                                    color: var(--color-table-secondary);
                                    text-transform: none;
                                }

                                &::before {
                                    margin-right: 2px;
                                }
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                &.opened {
                    .toggle {
                        background-color: var(--color-collapse-bg);
                        border-radius: $border-radius-primary $border-radius-primary 0 0;

                        i {
                            @include icon-before-code($icon-chevron-top);
                        }
                    }

                    .content {
                        display: block;
                    }
                }
            }

            &:last-child {
                .collapse {
                    margin: 0 42px 0 46px;
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .bulk-search-prequals {
        .prequals {
            .service {
                .app-checkbox {
                    font-size: 14px;
                }

                .service-types {
                    flex: 1 0 100%;
                    max-width: 100%;

                    flex-wrap: wrap;
                }
            }

            .services {
                .collapse {
                    margin: 0 0 16px 46px;
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .bulk-search-prequals {
        padding: 0;

        & > .head {
            padding: 24px 2px 16px 8px;

            .info {
                margin-bottom: 0;
                font-size: 16px;
                line-height: 18px;
            }

            .actions {
                margin-bottom: 0;

                .btn {
                    margin-left: 16px;
                    
                    span {
                        display: none;
                    }
                }
            }
        }

        .prequals {
            font-size: 14px;

            .app-checkbox {
                align-items: flex-start;

                &:before {
                    margin-right: 6px;
                }
            }
            
            .service {
                margin: 0 8px;
                padding: 8px 0;

                .service-types {
                    flex-wrap: wrap;

                    .tag {
                        margin: 4px 0 0 #{ 24px + 6px };
                        padding: 4px 8px 4px 4px;
                    }
                }
            }

            .services {
                margin: 0 8px;

                .service {
                    padding: 8px 0;
                }

                .collapse {
                    margin: 0 0 16px #{ 24px + 6px };

                    .toggle {
                        padding: 4px 12px 4px 14px;
                    }

                    .content {
                        padding: 12px;

                        .tag {
                            margin: 4px 0 0 #{ 24px + 2px };
                        }
                    }
                }
            }
        }
    }
}
</style>
